.tooltip {
  visibility: hidden;
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 90%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  will-change: opacity, visibility;
}

.fixed {
  position: fixed;
}

.arrow {
  position: absolute;
  background: inherit;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}

.no-arrow {
  display: none;
}

.clickable {
  pointer-events: auto;
}

.show {
  visibility: visible;
  opacity: var(--rt-opacity);
}

/** Types variant **/
.dark {
  background: var(--rt-color-dark);
  color: var(--rt-color-white);
}

.light {
  background-color: var(--rt-color-white);
  color: var(--rt-color-dark);
}

.success {
  background-color: var(--rt-color-success);
  color: var(--rt-color-white);
}

.warning {
  background-color: var(--rt-color-warning);
  color: var(--rt-color-white);
}

.error {
  background-color: var(--rt-color-error);
  color: var(--rt-color-white);
}

.info {
  background-color: var(--rt-color-info);
  color: var(--rt-color-white);
}
